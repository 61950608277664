<template>
  <div id="router" class="router">
    <div></div>

    <div class="container-with-sidebar">
      <div class="sidebar"></div>
      <div>
        <splitpanes class="default-theme"
          @ready="handlePanelReady"
          :push-other-panes="false" :horizontal="isMobileOnly ? false : true" @resized="handlePaneResize">
          <pane min-size="20" max-size="80">
            <div class="pane-wrapper">
              <div class="pane-title blue-light">
                <i class="bi bly-output"></i>
                Sources
                <span class="loading" v-show="loadings.sources">
                  <i class="bi bly-loading red bi-is-spinning"></i>
                </span>
                <div class="title-actions">
                  <el-select
                    size="small"
                    placeholder="Select group"
                    clearable
                    @change="sourceGroupChanged"
                    v-model="selectedSourceGroup" value-key="id">
                    <el-option
                      v-for="group in sourceGroups"
                      :key="group.id"
                      :label="group.name"
                      :value="group">
                    </el-option>
                  </el-select>
                  <el-divider direction="vertical" v-if="!isMobileOnly"></el-divider>
                  <div class="search" v-if="!isMobileOnly">
                    <el-input
                      placeholder="Search sources..."
                      size="small"
                      clearable
                      @change="searchSourceChanged"
                      v-model="searchSource">
                      <i slot="suffix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                  </div>
                </div>
              </div>
              <div v-bar>
                <div class="pane-bar-wrapper sources">
                  <div class="item-group">
                    <!--<el-divider content-position="left">{{key}}</el-divider>-->
                    <div class="item-wrapper"
                      v-for="(source, index) in filteredSources"
                      :key="source.id">
                      <div class="item"
                        @click="routSource(source, selectedDestinations)"
                        :class="{
                          active: selectedSource === source,
                          connected: activeDestinationSource.id === source.id,
                          disabled: !activeDestinationSource
                            && (activeDestinationSource.originRouterId
                            !== source.originRouterId),
                          'show-take': showTakeId === source.id,
                          'show-loading': loadings.source === source.id,
                        }">
                        <div class="changing-route">
                          <i class="bi bly-loading red bi-is-spinning"></i>
                        </div>
                        <el-popconfirm
                          confirmButtonText='OK'
                          cancelButtonText='Cancel'
                          class="take-action"
                          @confirm="endRouteCommand(source, selectedDestinations)"
                          title="Are you sure you want to take this?"
                        >
                          <div class="w-full !max-w-none" slot="reference">
                            TAKE
                          </div>
                        </el-popconfirm>
                          <!-- <div class="take-action"
                            @click="sendRouteCommand(source, selectedDestination)">
                            TAKE
                          </div> -->
                        <div class="item-avatar"
                          :title="source.originRouterId">
                          <el-avatar shape="square" size="medium"
                          :style="{ background: originRouterColor(source.originRouterId),}"
                          >{{index + 1}}</el-avatar>
                        </div>
                        <div>
                          <div class="item-name">
                            {{source.name}}
                          </div>
                          <div class="item-desc">
                            <div v-if="source.routedDestinations.items">
                              <div
                                class="not-connected"
                                v-if="source.routedDestinations.items.length === 0">
                                not routed
                              </div>
                            </div>
                            <div v-if="source.routedDestinations.items.length > 0">
                              <el-tooltip>
                                <div slot="content">
                                  <div
                                    class="routed"
                                    v-for="d in source.routedDestinations.items"
                                    :key="d.id"
                                    >{{d.name}}</div>
                                </div>
                                <div>
                                  <span class="routed">
                                    {{source.routedDestinations.items[0].name}}
                                  </span>
                                  <span
                                    class="routed"
                                    v-show="source.routedDestinations.items.length > 0"
                                    >... ({{source.routedDestinations.items.length}})
                                  </span>
                                </div>
                              </el-tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </pane>
          <pane min-size="20" :size="panelBottomSize" class="pane-dest">
            <div class="pane-wrapper">
              <div class="pane-title green">
                <i class="bi bly-input"></i>
                Destinations
                <span class="loading" v-show="loadings.destinations">
                  <i class="bi bly-loading red bi-is-spinning"></i>
                </span>
                <div class="title-actions">
                  <el-select
                    size="small"
                    placeholder="Select group"
                    clearable
                    @change="destGroupChanged"
                    v-model="selectedDestGroup" value-key="id">
                    <el-option
                      v-for="group in destinationGroups"
                      :key="group.id"
                      :label="group.name"
                      :value="group">
                    </el-option>
                  </el-select>
                  <el-divider direction="vertical" v-if="!isMobileOnly"></el-divider>
                  <div class="search" v-if="!isMobileOnly">
                    <el-input
                      placeholder="Search destination..."
                      size="small"
                      clearable
                      @change="searchDestinationChanged"
                      v-model="searchDestination">
                      <i slot="suffix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                  </div>
                </div>
              </div>
              <div v-bar
                   @mousedown="startDragSelect"
                   @mousemove="updateDragSelect"
                   @mouseup="endDragSelect"
                   @mouseleave="cancelDragSelect">
                <div class="pane-bar-wrapper destinations">
                  <div class="drag-selection" v-show="isDragging" :style="dragSelectStyle"></div>
                  <div class="item-group">
                    <!--<el-divider content-position="left">{{key}}</el-divider>-->
                    <div class="item-wrapper"
                      v-for="(dest, index) in filteredDestinations"
                      :key="dest.id">
                      <div class="item destination user-select-none"
                        @mousedown.prevent="handleDestinationMouseDown($event, dest)"
                        @click.stop="handleDestinationClick($event, dest)"
                        :ref="`destination-${dest.id}`"
                        :class="{
                          active: isDestinationSelected(dest.id),
                          connected: activeSourceDestinations.indexOf(dest.id) !== -1,
                          disabled: !activeSourceDestinations
                            && (activeSourceDestinations.originRouterId
                            !== dest.originRouterId),
                          'multi-selected': selectedDestinationsIds.includes(dest.id),
                        }"
                        >
                        <div class="item-avatar"
                          :title="dest.originRouterId"
                          >
                          <el-avatar shape="square" size="medium"
                          :style="{ background: originRouterColor(dest.originRouterId),}">{{index + 1}}</el-avatar>
                        </div>
                        <div class="user-select-none text-select-none pointer-events-none">
                          <div class="item-name">
                            {{dest.name}}
                          </div>
                          <div class="item-desc">
                            <span v-if="dest.routedSource">{{dest.routedSource.name}}</span>
                            <!-- <span class="text-red-500 font-bold" v-if="!dest.routedSource">ERROR</span> -->
                          </div>
                        </div>
                        <div class="item-actions"
                          v-show="false" v-if="isAdmin || acl.ro">
                          <el-tooltip
                            v-if="selectedSource
                              && activeSourceDestinations.indexOf(dest.id) === -1">
                            <div slot="content">
                              Route <strong class="blue-light">{{selectedSource.name}}</strong> here
                            </div>
                            <i class="bi bly-route icon-btn blue-light"
                              @click="sendRouteCommand(selectedSource, dest)"></i>
                          </el-tooltip>
                          <el-tooltip
                            v-show="false"
                            v-if="selectedSource
                              && activeSourceDestinations.indexOf(dest.id) !== -1">
                            <div slot="content">
                              Unassign <strong class="blue-light"> {{selectedSource.name}}</strong>
                            </div>
                            <i class="bi bly-close icon-btn red"
                              @click="sendRouteCommand(selectedSource, dest)"></i>
                          </el-tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </pane>
        </splitpanes>
      </div>
    </div>
    <!-- -------------------------------------------------------------------------------------- -->
    <!-- DILOGS      -------------------------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------------------- -->

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- NAV OPTIONS -------------------------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------------------- -->
    <div class="config mv-config">
      <!--<el-checkbox v-model="useTake"
        border
        label="Use take">
      </el-checkbox>
      -->
      <el-tooltip content="Help" placement="right">
        <el-popover
          placement="top-start"
          width="300"
          trigger="hover">
          <el-alert :closable="false">
            Current state refreshes every 15 seconds
          </el-alert>
          <h3><strong>Keybindings</strong></h3>
          <div>
            <div>
              Hold <el-tag size="small">SHIFT</el-tag> to disable scrolling.
            </div>
            <div>
              Hold <el-tag size="small">CMD</el-tag> (Mac) or <el-tag size="small">CTRL</el-tag> (Windows) to select multiple destinations.
            </div>
            <div>
              Click and drag to select multiple destinations.
            </div>
          </div>
          <i slot="reference" class="bi bly-question icon-btn"></i>
        </el-popover>
      </el-tooltip>
      <!--
      <el-select v-model="seletedRouterGroup" value-key="id" v-if="!isMobileOnly">
        <el-option
          v-for="group in routerGroups.items"
          :key="group.id"
          :label="group.name"
          :value="group">
        </el-option>
      </el-select>
      -->
    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import { isMobileOnly } from 'mobile-device-detect';
import _ from 'lodash';
import { Splitpanes, Pane } from 'splitpanes';
import ColorGenerator from '@/services/ColorGenerator';
import 'splitpanes/dist/splitpanes.css';

export default {
  name: 'router',
  components: { Splitpanes, Pane },
  data() {
    return {
      isMobileOnly,
      activeSource: null,
      loadingInfo: null,
      loadingRoute: null,
      hoveredDest: null,
      hoveredSource: null,
      hoverDest: null,
      useTake: true,
      showTakeId: null,

      seletedRouterGroup: null,
      selectedDestGroup: null,
      selectedSourceGroup: null,

      // routing
      selectedSource: null,
      selectedDestination: null,
      selectedDestinationsIds: [],

      // drag selection
      isDragging: false,
      dragStartX: 0,
      dragStartY: 0,
      dragEndX: 0,
      dragEndY: 0,
      
      // dialogs
      createRouterDialog: false,
      createRouterSourceDialog: false,
      createRouterDestinationDialog: false,
      createRouterGroupDialog: false,

      // forms
      routerForm: {},
      routerGroupForm: {},
      routerSourceForm: {},
      routerDestinationForm: {},

      keys: {},
      searchDestination: '',
      searchSource: '',
      panelBottomSize: localStorage.getItem('router_panelBottomSize') || 50,
    };
  },

  created() {
    this.init();
    this.initInterval();
    window.addEventListener('keyup', this.keyUp);
    window.addEventListener('keydown', this.keyDown);
  },

  beforeDestroy() {
    window.clearInterval(this.interval);
    this.interval = null;
    window.removeEventListener('keyup', this.keyUp);
    window.removeEventListener('keydown', this.keyDown);
  },

  computed: {
    ...mapState({
      aclEx: (state) => state.userAccount.userPermissions,
      acl: (state) => state.userAccount.aclCurrent,
      isAdmin: (state) => state.userAccount.isAdmin,
      client: (state) => state.userAccount.client,
      account: (state) => state.userAccount.account,
      clients: (state) => state.userAccount.clients,
      siteList: (state) => state.router.siteList,
      selection: (state) => state.router.selectedSite,
      loadings: (state) => state.router.loadings,

      // routers: (state) => state.router.routers,
      routerSources: (state) => state.router.routerSources,
      routerDestinations: (state) => state.router.routerDestinations,
      routerGroups: (state) => state.router.routerGroups,

      streamInformation: (state) => state.router.streamInformation,
    }),

    paneMinimalSize() {
      return this.isMobileOnly ? 50 : 25;
    },

    activeSourceDestinations() {
      if (this.hoveredSource) {
        return this.hoveredSource.routedDestinations.items.map((dest) => dest.id);
      }
      if (this.selectedSource) {
        return this.selectedSource.routedDestinations.items.map((dest) => dest.id);
      }
      return [];
    },

    activeDestinationSource() {
      if (this.hoveredDest) {
        console.log('test xxx 1', this.hoveredDest);
        return this.hoveredDest.routedSource;
      }
      if (this.selectedDestination && this.selectedDestination.routedSource) {
        console.log('test xxx 2', this.selectedDestination);
        return this.selectedDestination.routedSource;
      }
      console.log('test xxx 3', []);
      return {};
    },

    filteredSources() {
      const filterBySearchString = this.searchSource.length > 2;
      if (filterBySearchString) {
        const seachString = this.searchSource.toLowerCase();
        return this.routerSources
          .filter((item) => item !== null && item.name.toLowerCase().indexOf(seachString) !== -1)
          .sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          });
      }
      if (!this.selectedSourceGroup) {
        return this.routerSources
          .slice(0).sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          });
      }
      let items = this.routerSources
        .filter((item) => {
          const groupIds = item.routerGroups.items.map((group) => group.routerGroup.id);
          return groupIds.indexOf(this.selectedSourceGroup.id) !== -1;
        })
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
        console.log('router groups: sources', items);
  
      return items;
    },

    filteredDestinations() {
      const filterBySearchString = this.searchDestination.length > 2;
      if (filterBySearchString) {
        const seachString = this.searchDestination.toLowerCase();
        return this.routerDestinations
          .filter((item) => item.name.toLowerCase().indexOf(seachString) !== -1)
          .sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          });
      }
      if (!this.selectedDestGroup) {
        return this.routerDestinations
          .slice(0).sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          });
      }
      return this.routerDestinations
        .filter((item) => {
          const groupIds = item.routerGroups.items.map((group) => group.routerGroup.id);
          return groupIds.indexOf(this.selectedDestGroup.id) !== -1;
        })
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    /**
     * Retrieves the source groups from the router groups.
     *
     * @return {Array} An array of source groups.
     */
     sourceGroups() {
      if (!this.routerGroups) return [];
      console.log('router groups: ', this.accountId, this.clientId);
      const items = this.routerGroups.items.filter((item) => item.sources.items.length > 0)
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });

      return items;
    },

    destinationGroups() {
      if (!this.routerGroups) return [];
      return this.routerGroups.items.filter((item) => item.destinations.items.length > 0)
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    groupedSources() {
      const mappedSources = this.routerSources.map((item) => ({
        source: item,
        groups: item.routerGroups.items.map((group) => group.routerGroup.name),
      }));
      return _.groupBy(mappedSources, (item) => item.groups);
    },

    groupedDestinations() {
      const mappedDest = this.routerDestinations.map((item) => ({
        dest: item,
        groups: item.routerGroups.items.map((group) => group.routerGroup.name),
      }));
      return _.groupBy(mappedDest, (item) => item.groups);
    },

    selectedDestinationId() {
      return this.selectedDestination ? this.selectedDestination.id : null;
    },

    accountId() {
      return this.account.code;
    },

    clientId() {
      return this.client.code;
    },

    claimsCanEdit() {
      return [
        `ro.${this.accountId}.${this.clientId}`,
      ];
    },

    claimsCanAccess() {
      return [
        `rv.${this.accountId}.${this.clientId}`,
        `ro.${this.accountId}.${this.clientId}`,
      ];
    },

    originRouterColor() {
      return (id) => ColorGenerator.generateNewColor(id);
    },

    selectedDestinations() {
      // Check both multi-selection array and single-selected destination
      if (this.selectedDestinationsIds.length) {
        return this.filteredDestinations.filter(dest => 
          this.selectedDestinationsIds.includes(dest.id)
        );
      } else if (this.selectedDestination) {
        // Return the single selected destination as an array
        return [this.selectedDestination];
      }
      return null;
    },
    
    dragSelectStyle() {
      const left = Math.min(this.dragStartX, this.dragEndX);
      const top = Math.min(this.dragStartY, this.dragEndY);
      const width = Math.abs(this.dragEndX - this.dragStartX);
      const height = Math.abs(this.dragEndY - this.dragStartY);
      
      return {
        left: `${left}px`,
        top: `${top}px`,
        width: `${width}px`,
        height: `${height}px`
      };
    },
  },

  mounted() {
  },
  watch: {
    seletedRouterGroup() {
      this.selectedSource = null;
      this.selectedDestination = null;
    },
    panelBottomSize() {

      localStorage.setItem('router_panelBottomSize', this.panelBottomSize);
    },
  },

  methods: {
    ...mapActions({
      fetchRouterGroups: 'router/fetchRouterGroups',
      fetchRouterDestinations: 'router/fetchRouterDestinations',
      fetchRouterSources: 'router/fetchRouterSources',
      fetchRouters: 'router/fetchRouters',

      fetchRouter: 'router/fetchRouter',
      fetchRouterDestination: 'router/fetchRouterDestination',
      fetchRouterGroup: 'router/fetchRouterGroup',
      fetchRouterSource: 'router/fetchRouterSource',

      deleteRouter: 'router/deleteRouter',
      deleteRouterDestination: 'router/deleteRouterDestination',
      deleteRouterGroup: 'router/deleteRouterGroup',
      deleteRouterSource: 'router/deleteRouterSource',

      updateRouter: 'router/updateRouter',
      updateRouterDestination: 'router/updateRouterDestination',
      updateRouterGroup: 'router/updateRouterGroup',
      updateRouterSource: 'router/updateRouterSource',

      createRouter: 'router/createRouter',
      createRouterDestination: 'router/createRouterDestination',
      createRouterGroup: 'router/createRouterGroup',
      createRouterSource: 'router/createRouterSource',

      changeRoute: 'router/changeRoute',
    }),
    ...mapMutations({
      setSelectedSite: 'router/setSelectedSite',
    }),

    handlePaneResize(e) {
      console.log('handlePaneResize', e);
      localStorage.setItem('router_panelBottomSize', e[1].size);
    },
    handlePanelReady() {
      console.log('handlePanelReady');
      this.panelBottomSize = localStorage.getItem('router_panelBottomSize') || 50;
    },

    keyDown(e) {
      this.keys[e.key] = true;
    },

    keyUp(e) {
      this.keys[e.key] = false;
    },

    showTake(source) {
      this.showTakeId = source.id;
    },

    toggleSource(source) {
      if (this.selectedSource === source) {
        this.selectedSource = null;
        return;
      }
      this.selectedSource = source;
      // this.selectedDestination = null;
      if (this.keys.Shift) return;

      this.$forceUpdate();
      try {
        setTimeout(() => {
          document.querySelector('.destinations').scroll({
            top: document.querySelector('.destinations .connected').offsetTop - 10,
            behavior: 'smooth',
          });
        }, 100);
      } catch (e) {
        console.warn('no connected destinations', e);

      }
    },

    toggleDest(dest) {
      this.showTakeId = null;
      if (this.selectedDestination === dest) {
        this.selectedDestination = null;
        return;
      }
      this.selectedDestination = dest;
      this.selectedSource = null;
      this.$forceUpdate();

      if (this.keys.Shift) return;
      try {
        setTimeout(() => {
          const el = document.querySelector('.sources .connected');
          if (!el) return;
          document.querySelector('.sources').scroll({
            top: el.offsetTop - 10,
            behavior: 'smooth',
          });
        }, 100);
      } catch (e) {
        console.warn('no connected sources', e);
      }
    },

    initInterval() {
      this.interval = setInterval(async () => {
        await this.fetchRouterDestinations();
        if (this.selectedDestination) {
          this.filteredDestinations.forEach((dest) => {
            if (dest.id !== this.selectedDestination.id) return;
            this.selectedDestination.routedSource = dest.routedSource;
          });
        }
        await this.fetchRouterSources();
        this.$forceUpdate();
      }, 15000);
    },

    async init() {
      this.searchDestination = localStorage.getItem('router_searchDestination') || '';
      this.searchSource = localStorage.getItem('router_searchSource') || '';

      this.selectedSourceGroup = JSON.parse(localStorage.getItem('router_selectedSourceGroup')) || null;
      this.selectedDestGroup = JSON.parse(localStorage.getItem('router_selectedDestGroup')) || null;
      try {
        // await this.fetchRouters();
        await this.fetchRouterDestinations();
        await this.fetchRouterSources();
        await this.fetchRouterGroups();
      } catch (error) {
        this.$message({
          showClose: true,
          message: 'Oops, something went wrong.',
          type: 'error',
        });
      }
    },

    routSource(source, destinations) {
      const hasPermission = this.isAdmin || this.acl.ro;
      if (!hasPermission) return;
      this.showTakeId = null;
      
      // Check if we have selected destinations
      if (!destinations || (Array.isArray(destinations) && destinations.length === 0 
          && !this.selectedDestination)) return;
      
      if (this.useTake) {
        this.showTakeId = source.id;
        return;
      }

      this.sendRouteCommand(source, destinations || [this.selectedDestination]);
    },

    async sendRouteCommand(source, destinations) {
      // Convert single destination to array for consistency
      const destArray = Array.isArray(destinations) ? destinations : [destinations];
      
      // Extract destination IDs for the API call
      const destinationIds = destArray.map(dest => dest.id);
      
      const command = {
        clientId: this.clientId,
        accountId: this.accountId,
        sourceId: source.id,
        destinationIds: destinationIds,
        claimsCanAccess: this.claimsCanAccess,
        claimsCanEdit: this.claimsCanEdit,
      };
      
      await this.changeRoute(command);
      this.showTakeId = null;
      
      // Clear selection after successful route
      this.clearDestinationsSelection();
    },
    
    async endRouteCommand(source, destinations) {
      await this.sendRouteCommand(source, destinations);
    },

    isDestinationSelected(destId) {
      return this.selectedDestinationsIds.includes(destId) || 
        (this.selectedDestination && this.selectedDestination.id === destId);
    },

    handleDestinationMouseDown(event, dest) {
      // Start drag select operation if not cmd/ctrl clicking
      if (!event.metaKey && !event.ctrlKey) {
        this.dragStartX = event.clientX;
        this.dragStartY = event.clientY;
      }
    },

    handleDestinationClick(event, dest) {
      // Prevent default to avoid text selection
      event.preventDefault();
      
      // If cmd/ctrl key is pressed, toggle this destination in multi-selection mode
      if (event.metaKey || event.ctrlKey) {
        this.toggleDestInSelection(dest);
      } else {
        // If not cmd/ctrl clicking, clear selection and select only this destination
        this.clearDestinationsSelection();
        this.toggleDest(dest);
        // Make sure the source's TAKE action still works with single selection
        // by setting this destination as the selected one
        this.selectedDestination = dest;
      }
    },
    
    toggleDestInSelection(dest) {
      const index = this.selectedDestinationsIds.indexOf(dest.id);
      if (index === -1) {
        // Add to selection
        this.selectedDestinationsIds.push(dest.id);
      } else {
        // Remove from selection
        this.selectedDestinationsIds.splice(index, 1);
      }
      // Set as last selected destination for display purposes
      this.selectedDestination = dest;
    },
    
    clearDestinationsSelection() {
      this.selectedDestinationsIds = [];
    },
    
    startDragSelect(event) {
      // Don't start drag select if cmd/ctrl is pressed
      if (event.metaKey || event.ctrlKey) return;
      
      // Only start drag on left mouse button
      if (event.button !== 0) return;
      
      this.isDragging = true;
      this.dragStartX = event.clientX;
      this.dragStartY = event.clientY;
      this.dragEndX = event.clientX;
      this.dragEndY = event.clientY;
      
      // Clear existing selection unless shift key is held
      if (!event.shiftKey) {
        this.clearDestinationsSelection();
        this.selectedDestination = null;
      }
    },
    
    updateDragSelect(event) {
      if (!this.isDragging) return;
      
      this.dragEndX = event.clientX;
      this.dragEndY = event.clientY;
    },
    
    endDragSelect(event) {
      if (!this.isDragging) return;
      
      // Select all destinations that are within the drag rectangle
      this.selectDestinationsInDragArea();
      
      // End dragging
      this.isDragging = false;
    },
    
    cancelDragSelect() {
      this.isDragging = false;
    },
    
    selectDestinationsInDragArea() {
      const left = Math.min(this.dragStartX, this.dragEndX);
      const top = Math.min(this.dragStartY, this.dragEndY);
      const right = Math.max(this.dragStartX, this.dragEndX);
      const bottom = Math.max(this.dragStartY, this.dragEndY);
      
      // Minimal size required to trigger selection (prevents accidental clicks)
      if (right - left < 5 || bottom - top < 5) return;
      
      // Check each destination element against the drag area
      this.filteredDestinations.forEach(dest => {
        const el = this.$refs[`destination-${dest.id}`];
        if (!el || !el[0]) return;
        
        const rect = el[0].getBoundingClientRect();
        
        // Check if the rectangle overlaps with our drag selection
        if (rect.left <= right && rect.right >= left && rect.top <= bottom && rect.bottom >= top) {
          // Add to selection if not already selected
          if (!this.selectedDestinationsIds.includes(dest.id)) {
            this.selectedDestinationsIds.push(dest.id);
          }
          
          // Set as selected destination for visual feedback
          this.selectedDestination = dest;
        }
      });
    },

    // OLD STUFF
    async deleteRouterPrompt(router) {
      const isConfirm = await this.showPrompt(`Are you sure you want to remove <b>${router.name}</b>?`);
      if (!isConfirm) return false;
      await this.deleteRouter(router);
      return null;
    },
    async deleteRouterSourcePrompt(router) {
      const isConfirm = await this.showPrompt(`Are you sure you want to remove <b>${router.name}</b>?`);
      if (!isConfirm) return false;
      await this.deleteRouterSource(router.id);
      return null;
    },
    async deleteRouterDestinationPrompt(router) {
      const isConfirm = await this.showPrompt(`Are you sure you want to remove <b>${router.name}</b>?`);
      if (!isConfirm) return false;
      await this.deleteRouterDestination(router.id);
      return null;
    },

    async showPrompt(confirmationMessage) {
      const confirmation = await this.$confirm(confirmationMessage, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        center: true,
        dangerouslyUseHTMLString: true,
      });
      return confirmation === 'confirm';
    },

    searchDestinationChanged() {
      localStorage.setItem('router_searchDestination', this.searchDestination);
    },
    searchSourceChanged() {
      localStorage.setItem('router_searchSource', this.searchSource);
    },
    sourceGroupChanged() {
      this.selectedDestination = null;
      localStorage.setItem('router_selectedSourceGroup', JSON.stringify(this.selectedSourceGroup));
    },
    destGroupChanged() {
      this.selectedDestination = null;
      localStorage.setItem('router_selectedDestGroup', JSON.stringify(this.selectedDestGroup));
    },
  },
};
</script>

<style>
.el-loading-spinner svg {
  margin: 0 auto;
}

.green {
  color: #00FF80;
}

.green-dark {
  color: #77ca17;
}

.blue-light {
  color: #00D4FF;
}

#router.router-vh .container-with-sidebar .item-wrapper .item {
  grid-template-columns: 1fr;
  grid-template-rows: 91px auto;
  height: 137px;
  width: 150px;
  text-align: center;
  align-items: start;
  grid-gap: 0;
}
#router.router-vh .container-with-sidebar .item-wrapper .item .item-name {
  margin: 0 10px;
  font-size: 13px;
}
#router.router-vh .container-with-sidebar .item-wrapper .item .item-avatar {
  padding: 0px;
}
#router.router-vh .container-with-sidebar .item-wrapper .item .item-avatar .el-avatar {
  margin: 0 auto;
  width: 120px;
  height: 70px;
  line-height: 75px;
  margin-top: 10px;
}
#router .el-divider .el-divider__text {
  position: absolute;
  background-color: #13191D;
}
#router .container-with-sidebar {
  display: grid;
  grid-template-columns: auto;
}
#router .container-with-sidebar .pane-wrapper {
  height: 100%;
}
#router .container-with-sidebar .pane-wrapper .vb {
  height: 100%;
}
#router .container-with-sidebar .pane-bar-wrapper {
  padding-left: 2px;
  padding-top: 2px;
  padding-bottom: 50px;
}
#router .container-with-sidebar .pane-title {
  font-size: 14px;
  text-transform: uppercase;
  margin: 10px 0px;
  font-weight: 500;
  letter-spacing: 0.3px;
  position: relative;
}
#router .container-with-sidebar .pane-title .bi {
  position: relative;
  top: 1px;
  margin-right: 8px;
}
#router .container-with-sidebar .pane-title .title-actions {
  position: absolute;
  right: 20px;
  top: -7px;
}
#router .container-with-sidebar .pane-title .title-actions input {
  background: transparent;
  border-color: transparent;
  color: #CDD6EE;
}
#router .container-with-sidebar .pane-title .title-actions .search {
  display: inline-block;
}
#router .container-with-sidebar .item-wrapper {
  display: inline-block;
  margin-bottom: 12px;
  margin-right: 12px;
}
#router .container-with-sidebar .item-wrapper .item-actions {
  position: absolute;
  right: 0;
  top: 0;
}
#router .container-with-sidebar .item-wrapper .item {
  display: grid;
  grid-template-columns: 44px auto;
  grid-gap: 8px;
  width: 252px;
  height: 52px;
  align-items: center;
  overflow: hidden;
  background: #242A33;
  border-radius: 2px;
  position: relative;
}
#router .container-with-sidebar .item-wrapper .item.active, #router .container-with-sidebar .item-wrapper .item:hover {
  outline: 2px solid #00D4FF;
}
#router .container-with-sidebar .item-wrapper .item.active {
  outline: 2px solid #ff9f31 !important;
}
#router .container-with-sidebar .item-wrapper .item.active .item-name {
  color: #ff9f31;
}
#router .container-with-sidebar .item-wrapper .item.active .el-avatar--square {
  background: #ff9f31;
}
#router .container-with-sidebar .item-wrapper .item .take-action, #router .container-with-sidebar .item-wrapper .item .changing-route {
  position: absolute;
  background: #ea0d51;
  top: 5px;
  bottom: 5px;
  grid-column: 1/span 2;
  right: 5px;
  left: 5px;
  max-width: 300px !important;
  color: #fff;
  line-height: 45px;
  text-align: center;
  display: none;
}
#router .container-with-sidebar .item-wrapper .item .changing-route {
  background: rgba(36, 42, 51, 0.78);
}
#router .container-with-sidebar .item-wrapper .item.show-take .take-action {
  display: block;
}
#router .container-with-sidebar .item-wrapper .item.show-loading .changing-route {
  display: block;
  z-index: 999;
}
#router .container-with-sidebar .item-wrapper .item.destination.active, #router .container-with-sidebar .item-wrapper .item.destination:hover {
  outline: 2px solid #00FF80;
}
#router .container-with-sidebar .item-wrapper .item.destination.connected {
  outline: 2px solid #00D4FF;
}
#router .container-with-sidebar .item-wrapper .item.destination.connected .item-name {
  color: #ff9f31;
}
#router .container-with-sidebar .item-wrapper .item.destination.connected .el-avatar--square {
  background: #ff9f31;
}
#router .container-with-sidebar .item-wrapper .item.connected-dest {
  outline: 2px solid #ff9f31;
}
#router .container-with-sidebar .item-wrapper .item.connected {
  outline: 2px solid #ff9f31;
}
#router .container-with-sidebar .item-wrapper .item.connected .item-name {
  color: #ff9f31;
}
#router .container-with-sidebar .item-wrapper .item.connected .el-avatar--square {
  background: #ff9f31;
}
#router .container-with-sidebar .item-wrapper .item.disabled {
  opacity: 0.5;
  pointer-events: none;
}
#router .container-with-sidebar .item-wrapper .item .el-avatar--square {
  border-radius: 2px;
}
#router .container-with-sidebar .item-wrapper .item div {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}
#router .container-with-sidebar .item-wrapper .item-avatar {
  align-content: center;
  display: grid;
  padding-left: 8px;
}
#router .container-with-sidebar .item-wrapper .item-name {
  color: #CDD6EE;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#router .container-with-sidebar .item-wrapper .item-desc {
  font-size: 12px;
  margin-bottom: -2px;
  line-height: 17px;
}
#router .container-with-sidebar .item-wrapper .item-desc .routed {
  display: inline-block;
  margin-right: 10px;
  color: #00D4FF;
}
#router .container-with-sidebar .item-wrapper .item-desc .not-connected {
  color: #FF5722;
  opacity: 0;
  display: none;
}
#router .container-with-sidebar .item-wrapper .item .item-actions {
  position: absolute;
  right: 0;
  top: 0;
}
#router .container-with-sidebar .item-wrapper .item .item-actions i {
  background: rgba(36, 42, 51, 0.71);
}

.mobile #router {
  overflow-x: scroll;
  margin: 10px 0px;
}
.mobile #router .wrapper {
  padding: 10px 0;
}
.mobile #router .item {
  width: 150px !important;
  grid-template-columns: 0 auto !important;
}
.mobile #router .item-avatar {
  visibility: hidden !important;
}
.mobile #router .default-theme.splitpanes .splitpanes__pane {
  padding: 0 0 0 20px;
}
.mobile #router .container-with-sidebar .pane-title .title-actions {
  right: 0;
  top: 0;
  position: relative;
  left: -16px;
}

.default-theme.splitpanes {
  height: calc(100vh - 60px);
}
.default-theme.splitpanes .splitpanes__pane {
  background: transparent;
  padding: 0 0 0 20px;
}
.default-theme.splitpanes .splitpanes__pane.pane-dest {
  background-color: rgba(0, 0, 0, 0.3);
}
.default-theme.splitpanes .splitpanes__splitter {
  background-color: rgba(0, 0, 0, 0.3);
  border-left: 1px solid transparent;
  border-top: 1px solid transparent;
}
.default-theme.splitpanes .splitpanes__splitter:before {
  margin-left: 0px;
  background-color: #66718D;
}
.default-theme.splitpanes .splitpanes__splitter:after {
  background-color: #66718D;
}

#router .container-with-sidebar .item-wrapper .item.destination.multi-selected,
#router .container-with-sidebar .item-wrapper .item.destination.multi-selected:hover {
  outline: 2px solid #00D4FF;
}

.pane-bar-wrapper {
  position: relative;
}

.drag-selection {
  position: fixed;
  border: 2px dashed #00D4FF;
  background-color: rgba(0, 212, 255, 0.05);
  z-index: 100;
  pointer-events: none;
  border-radius: 2px;
  backdrop-filter: blur(1px);
}
</style>
